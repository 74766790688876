import { FC } from "react";

import ReactMapGL, {
  NavigationControl,
  Marker,
  Popup,
  Source,
  Layer,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useAppSelector } from "../../store";

const MAPBOX_TOKEN =
  "pk.eyJ1Ijoia2FyYW5qYXRob3VsZGljb25pdW0iLCJhIjoiY2wzdm5zaTd6Mm5vNzNqcGF1eGd0MHU1ayJ9.uvOsI1paGHtzCRYsTV3wDQ"; // Set your mapbox token here

// const MapboxSatellite = 'mapbox://styles/mapbox/light-v10'
// const MapboxSatellite = "mapbox://styles/mapbox/satellite-v9";

//MapBox Default Styles
const MapboxStreets = 'mapbox://styles/mapbox/streets-v11'
const MapboxOutDoor = 'mapbox://styles/mapbox/outdoors-v11'
const MapboxLight = 'mapbox://styles/mapbox/light-v10'
const MapboxDark = 'mapbox://styles/mapbox/dark-v10'
const MapboxSatellite = 'mapbox://styles/mapbox/satellite-v9'
const MapboxSatelliteStreet = 'mapbox://styles/mapbox/satellite-streets-v11'
const MapboxNavigationDay = 'mapbox://styles/mapbox/navigation-day-v1'
const MapboxNavigationNight = 'mapbox://styles/mapbox/navigation-night-v1'

//MapBox Custom styles
const Custom1 = 'mapbox://styles/examples/cke97f49z5rlg19l310b7uu7j'
const Custom2 = 'mapbox://styles/surajfc/ckq0jexlc0h4418k00g3y1ora/draft'

export interface MapProps {}

export const Map: FC<MapProps> = (props) => {
  const coordinates = useAppSelector((state) => state.data.gps);
  const yaw = useAppSelector((state) => state.data.yaw);
  // const coordinates = useAppSelector(state => state.data.gps)

  console.log({ coordinates });

  const latitude = coordinates?.[0] ? coordinates[0] : 37.65116235039936;
  const longitude = coordinates?.[1] ? coordinates[1] : -122.41231011357793;

  return (
    <ReactMapGL
      mapboxAccessToken={MAPBOX_TOKEN}
      latitude={latitude}
      longitude={longitude}
      mapStyle={MapboxStreets}
      zoom={16}
      bearing={((yaw * 180) / 3.14) * -1.0 + 90}
      onClick={(e) => e.preventDefault()}
    >
      <Marker latitude={latitude} longitude={longitude} anchor="top">
        <svg
          width="55"
          height="52"
          viewBox="0 0 55 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_204_971)">
            <path
              d="M40.6668 36.5007C40.6668 36.5007 33.0001 32.0009 27.3335 32.0009C21.6668 32.0009 14.0001 36.5007 14.0001 36.5007L27.3335 12.0009L40.6668 36.5007Z"
              fill="#27AE60"
            />
            <path
              d="M39.9075 37.7943L39.9077 37.7945L39.9078 37.7945L39.908 37.7946L44.5669 40.5291L41.9843 35.7837L28.651 11.2838L27.3335 8.86289L26.0159 11.2838L12.6826 35.7837L10.1 40.5291L14.7589 37.7946L14.7591 37.7945L14.7592 37.7945L14.7594 37.7943L14.7617 37.793L14.7749 37.7853L14.8332 37.7517C14.8859 37.7215 14.966 37.676 15.071 37.6175C15.2812 37.5003 15.5908 37.3311 15.9813 37.127C16.7633 36.7183 17.8634 36.1734 19.1326 35.6295C21.7222 34.5198 24.8204 33.5009 27.3335 33.5009C29.8465 33.5009 32.9448 34.5198 35.5343 35.6295C36.8035 36.1734 37.9036 36.7183 38.6856 37.127C39.0761 37.3311 39.3857 37.5003 39.5959 37.6175C39.7009 37.676 39.781 37.7215 39.8337 37.7517L39.892 37.7853L39.9052 37.793L39.9075 37.7943Z"
              stroke="white"
              strokeWidth="3"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_204_971"
              x="0.199951"
              y="0.724976"
              width="54.267"
              height="50.8325"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="3" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_204_971"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_204_971"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </Marker>
    </ReactMapGL>
  );
};

export default Map;
