import { FC, PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { useMph } from "../../hooks/helpers";
import { useAppSelector } from "../../store";
import { IndicatorsBar } from "../IndicatorsBar";
import { ReactComponent as MphSvg } from "./mph.svg";
import { DriveState, TurnSignalState } from "./DriveState";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  width: 156px;
  height: 324px;
  background-color: rgba(0, 0, 0, 0.6);
`;

const Row = styled.div<{ paddingTop?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${(props) =>
    props.paddingTop
      ? css`
          padding-top: 10px;
        `
      : ``}
`;

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0.65;
  padding: 8px 0;
  max-height: 100%;
  /* background-color: blue; */
  row-gap: 12px;
  padding-top: 16px;
  /* overflow: hidden; */
`;
const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.35;
  /* background-color: red; */
  padding: 8px 0;
`;

const VehicleSpeed = styled.p`
  font-size: 56px;
  line-height: 68px;
  /* font-size: 40px; */
  font-weight: 400;
  color: white;
`;

// const
const SpeedBadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;

  min-width: 32px;
  height: 44px;
  background-color: white;
  border: 1px solid black;
  outline: 1.5px solid white;
  border-radius: 4px;
  padding-bottom: 1px;
  /* outline-color: white; */
`;

const SpeedLimitNumber = styled.p`
  font-size: 22px;
  line-height: 25px;
`;

const IndicatorBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-content: center; */
  align-items: flex-end;
  padding-right: 24px;
  /* align-content: flex-end; */
  /* margin-left: auto; */
  /* margin-right: 26px; */
  height: 100%;
`;

const SpeedLimitBadge: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <SpeedBadgeContainer className="w">
      <SpeedLimitNumber>{children}</SpeedLimitNumber>
      <MphSvg />
    </SpeedBadgeContainer>
  );
};

export const Telemetry = () => {
  // const currentMode = useAppSelector((state) => state.data.appMode);
  // const socket = useAppSelector((state) => state.data.socket);
  // const vehicleId = useAppSelector((state) => state.data.vehicleId);
  const distance = useAppSelector((state) => state.data.distance);
  // const rotate = useAppSelector((state) => state.data.rotate);
  const vehicleState = useAppSelector((state) => state.data.telemetry);
  const relay = useAppSelector((state) => state.data.relay);
  // const gps = useAppSelector((state) => state.data.gps);
  // const maxPlannedSpeedMph = useAppSelector(
  //   (state) => state.data.
  // );
  const maxPlannedSpeedMph = useAppSelector(
    (state) => state.data.maxPlannedSpeedMph
  );
  // const styles = useStyles();
  // const vehicleIds = useAppSelector((state) => state.data.vehicles);
  // const currentLeashInput = useAppSelector(
  //   (state) => state.data.currentLeashInput
  // );
  const { DelimitedSpeed: delimitedSpeed } = relay;
  console.log(
    "%c datalog ",
    "background: #2b2b2b; color: #00ffb7; font-weight: bold;",
    {
      relay,
      vehicleState,
      distance,
    }
  );
  const { Brake: brake, Speed: speed, Accel: accel } = vehicleState;

  // use delimited speed for a small triangle
  // both triangels go from bottom to top

  // mph sign -> use maxplannedspeed for now

  // accel=throttle

  const speedMph = useMph(speed);

  return (
    <Container>
      <Row>
        <LeftCol>
          <SpeedLimitBadge>{Math.round(maxPlannedSpeedMph)}</SpeedLimitBadge>
          <VehicleSpeed>{Math.round(speedMph) ?? 0}</VehicleSpeed>
        </LeftCol>
        <RightCol>
          <IndicatorBarContainer>
            <IndicatorsBar
              accel={accel ?? 0}
              brake={brake / 100.0 ?? 0}
              distance={distance ?? 0}
              delimitedSpeed={Math.round(delimitedSpeed ?? 0)}
              maxPlannedSpeed={Math.round(maxPlannedSpeedMph ?? 0)}
            />
          </IndicatorBarContainer>
        </RightCol>
      </Row>
      <Row paddingTop>
        <DriveState
          gearState={vehicleState.gearState}
          parkingEnabled={vehicleState.parkingBrake}
          turnSignalLeftEnabled={
            vehicleState.turnSignal === TurnSignalState.Left ||
            vehicleState.turnSignal === TurnSignalState.Hazard
          }
          turnSignalRightEnabled={
            vehicleState.turnSignal === TurnSignalState.Right ||
            vehicleState.turnSignal === TurnSignalState.Hazard
          }
        />
      </Row>
    </Container>
  );
};
