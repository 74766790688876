import { FC, useEffect, useMemo } from "react";
import styled from "styled-components";
import { VehiclesTable } from "../components/VehiclesTable";
import { Vehicle } from "../components/VehiclesTable/VehiclesTable";
import { useAppDispatch, useAppSelector } from "../store";
import { dataActions } from "../store/data/dataSlice";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  text-align: center;
  place-items: center;
  place-content: center;
  padding: 96px 0;
  /* overflow-x: hidden; */
  /* overflow-y: hidden; */
  /* overflow-y: auto; */
`;

export const SelectVehiclePage: FC = () => {
  const dispatch = useAppDispatch();
  const socket = useAppSelector((state) => state.data.socket);
  const vehiclesState = useAppSelector((state) => state.data.vehicles);

  const vehicles = useMemo<Vehicle[]>(() => {
    return (
      Object.keys(vehiclesState ?? {})
        .filter((key) => !!key?.trim())
        .map((vehicleId) => ({
          id: vehicleId,
          channel: vehiclesState[vehicleId],
        })) ?? []
    );
  }, [vehiclesState]);
  // console.log({ vehicles });

  useEffect(() => {
    dispatch(dataActions.intializeSocket({}));
  }, [dispatch]);

  useEffect(() => {
    if (socket) {
      socket.onmessage = function (event) {
        console.log(`[message] Data received from server: ${event.data}`);

        try {
          const parsedData = JSON.parse(event.data);
          if (parsedData.vehicle_list !== undefined) {
            dispatch(dataActions.setVehicles(parsedData.vehicle_list));
            return;
          }
        } catch {
          // Plain Text Data
        }
      };
    }
  }, [socket, dispatch]);

  const tableNode = useMemo(() => {
    return <VehiclesTable caption={!!vehicles?.length ? "Select Vehicle:" : "Loading Vehicles ..."} vehicles={vehicles ?? []} />;
  }, [vehicles]);

  return <Container>{tableNode}</Container>;
};

export default SelectVehiclePage;
