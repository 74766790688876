import { Provider } from "react-redux";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "@aws-amplify/auth";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { useNavigate } from "react-router";

import store from "./store";

import "./App.css";
import { VehiclePage } from "./pages/VehiclePage";
import { useCallback, useEffect, useState } from "react";
import { SignInPage } from "./pages/SignInPage";
import SelectVehiclePage from "./pages/SelectVehiclePage";
import styled from "styled-components";

const FollowLink = styled.a`
  color: #2763fd;
`;

const App = () => {
  const [isAuth, setIsAuth] = useState<boolean | null>(null);

  // const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((u) => {
        console.log({ currentUser: u });
        setIsAuth(true);
      })
      .catch(() => {
        setIsAuth(false);
      });
  }, []);

  const handleSignIn = useCallback((result: any) => {
    setIsAuth(true);
    window.location.href = "/vehicles";
    // navigate("/vehicles");
  }, []);

  if (isAuth === null) {
    return null;
  }

  if (isAuth === false) {
    return (
      <main>
        <SignInPage onSignIn={handleSignIn} />
      </main>
    );
  }

  const notFoundNode = (
    <main style={{ padding: 64, color: "black" }}>
      <p>No vehicle is selected!</p>
      <p>Please, check url that you are using</p>
      <FollowLink href="/vehicles">
        To select a vehicle - follow this link
      </FollowLink>
    </main>
  );

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={notFoundNode} />
            <Route path="*" element={notFoundNode} />
            <Route path="vehicles" element={<SelectVehiclePage />} />
            <Route path="vehicle">
              <Route index element={notFoundNode} />
              <Route path=":vehicleId" element={<VehiclePage />} />
            </Route>
            <Route path="*" element={notFoundNode} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
// export default withAuthenticator(App);
