import { useEffect } from "react";
import { useParams } from "react-router-dom";
import VehicleCameraView from "../containers/VehicleCameraView";
import { useAppDispatch } from "../store";
import { dataActions } from "../store/data/dataSlice";

export const VehiclePage = () => {
  const { vehicleId } = useParams<{ vehicleId?: string }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!vehicleId) {
      return;
    }
    
    dispatch(dataActions.setVehicleId(vehicleId));
  }, [vehicleId, dispatch]);

  if (!vehicleId) {
    return (
      <main>
        Error. No vehicle id is provided! Please, make sure you are using a
        right url link
      </main>
    );
  }

  return (
    <div>
      <VehicleCameraView vehicleId={vehicleId} />
    </div>
  );
};
