import { FC } from "react";
import styled from "styled-components";
import { ReactComponent as BigTriangleSvg } from "./assets/big-triangle-right-to-left.svg";
import { ReactComponent as SmallTriangleSvg } from "./assets/small-triangle-left-to-right.svg";

const Container = styled.div`
  display: flex;
  background: rgba(208, 208, 208, 0.75);

  position: relative;
  width: 15px;
  height: 125px;
  place-content: center;
  place-items: center;
`;

const TargetMaximumSpeed = styled.p`
  display: block;
  position: absolute;
  text-align: right;
  /* margin-left: auto; */
  right: 0;
  /* word-break: keep-all; */
  /* line-break: loose; */
  top: -32px;
  text-align: center;
  color: white;
  min-width: 100%;
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
`;

const MinimumSpeed = styled.p`
  position: absolute;
  bottom: -32px;
  text-align: center;
  color: white;
  min-width: 100%;
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
`;

const BrakeFill = styled.div<{ value: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 80%;

  background-color: rgba(0, 0, 0, 0.3);

  &:after {
    content: "";
    width: 100%;
    inset: 0;
    position: absolute;
    background-color: red;

    height: ${(props) => Math.round(props.value * 100)}%;
  }
`;

const ThrottleFill = styled.div<{ value: number }>`
  position: absolute;
  left: 0;
  right: 0;
  /* top: -80%; */
  top: 0;
  bottom: 20%;

  background-color: rgba(0, 0, 0, 0.1);

  &::after {
    content: "";
    width: 100%;
    left: 0;
    right: 0;
    top: ${(props) => 100 - Math.round(props.value * 100)}%;
    bottom: 0;
    position: absolute;
    background-color: #00b612;

    /* height: ${(props) => Math.round(props.value * 100)}%; */
  }
`;

const BigTriangle = styled(BigTriangleSvg)<{ value: number }>`
  position: absolute;
  left: -15px;
  top: auto;
  bottom: ${(props) => Math.round(props.value * 100)}%;
  z-index: 10;
  transform: translateY(50%) scaleX(-1);
`;
const SmallTriangle = styled(SmallTriangleSvg)<{ value: number }>`
  position: absolute;
  right: auto;
  left: 0;
  top: auto;
  bottom: ${(props) => Math.round(props.value * 100)}%;
  z-index: 10;
  transform: translateY(50%) scale(-1);
`;

export interface IndicatorsBarProps {
  accel: number;
  brake: number;
  distance: number;
  delimitedSpeed: number;
  maxPlannedSpeed: number;
}

export const IndicatorsBar: FC<IndicatorsBarProps> = (props) => {
  const { accel, brake, distance, delimitedSpeed, maxPlannedSpeed } = props;

  return (
    <Container>
      <TargetMaximumSpeed>{`${Math.round(
        (distance / 100) * maxPlannedSpeed
      )}/${maxPlannedSpeed}`}</TargetMaximumSpeed>
      <MinimumSpeed>0</MinimumSpeed>
      <ThrottleFill value={accel / 100.0}  />
      <BrakeFill value={brake} />
      <BigTriangle value={distance / 100} />
      <SmallTriangle
        value={maxPlannedSpeed ? delimitedSpeed / maxPlannedSpeed : 0}
      />
    </Container>
  );
};
