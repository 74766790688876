import { useCallback, useEffect, useState, useRef } from "react";
import {
  getOpenedXKeysPanels,
  requestXkeysPanels,
  setupXkeysPanel,
  XKeys,
} from "xkeys-webhid";
import { ID_V_SLIDER, ID_H_SLIDER, ID_UP, ID_DOWN } from "../constants";
import { useAppDispatch, useAppSelector } from "../store";
import { dataActions } from "../store/data/dataSlice";
import { ControlKeyIndex, XKeyIndex } from "../constants/xkeys";

export function useXkeys() {
  const vehicleId = useAppSelector((state) => state.data?.vehicleId);
  const dispatch = useAppDispatch();
  const [currentXkeys, setCurrentXkeys] = useState<XKeys | null>(null);

  const timeoutIdGlobRef = useRef<ReturnType<typeof setTimeout> | null>();

  const openDevice = useCallback(
    async (device: HIDDevice) => {
      const xkeys = await setupXkeysPanel(device);

      setCurrentXkeys(xkeys);

      console.log(`Connected to "${xkeys.info.name}"`);

      xkeys.on("down", (keyIndex: number) => {
        if (ControlKeyIndex.Ready === keyIndex) {
          dispatch(dataActions.sendReady());
        } else if (ControlKeyIndex.Authorize === keyIndex) {
          dispatch(dataActions.sendAuthorize());
        } else if (keyIndex === ControlKeyIndex.Eye) {
          dispatch(dataActions.toggleAppUI());
        } else if (ControlKeyIndex.Network === keyIndex) {
          dispatch(dataActions.intializeSocket({ vehicleId: vehicleId }));
          // dispatch(dataActions.setPressedXKey(XKeyIndex.Network));
        } else if (ControlKeyIndex.LeftControl === keyIndex) {
          dispatch(dataActions.setLateralOffset({ lateralOffset: "left", buttonId: ID_H_SLIDER }));
        } else if (ControlKeyIndex.RightControl === keyIndex) {
          dispatch(dataActions.setLateralOffset({ lateralOffset: "right", buttonId: ID_H_SLIDER }));
        } else if (ControlKeyIndex.RevertBack === keyIndex) {
          dispatch(dataActions.setLateralOffset({ lateralOffset: "reset", buttonId: ID_H_SLIDER }));
          dispatch(dataActions.setLongitudinalOffset({ longitudinalOffset: "reset", buttonId: ID_UP}));
        } else if (ControlKeyIndex.UpControl === keyIndex){
          dispatch(dataActions.setLongitudinalOffset({ longitudinalOffset: "up", buttonId: ID_UP}));
        } else if (ControlKeyIndex.BottomControl === keyIndex){
          dispatch(dataActions.setLongitudinalOffset({ longitudinalOffset: "down", buttonId: ID_DOWN}));
        } else if (ControlKeyIndex.Capture === keyIndex) {
          dispatch(dataActions.enableRearCamera(true));
        } else if (ControlKeyIndex.Record === keyIndex) {
          dispatch(dataActions.sendRecordingRequest())
        } else if (ControlKeyIndex.Flag === keyIndex) {
          dispatch(dataActions.sendEventRequest())
        } else if (ControlKeyIndex.Stop === keyIndex) {
          dispatch(dataActions.setDistance({ distance: 0 }));
        dispatch(
          dataActions.setCurrentLeashInput({
            currentLeashInput: 0,
          })
        );
          dispatch(
            dataActions.sendDistance({ distance: 0, buttonId: ID_V_SLIDER })
          );
        }
      });
      xkeys.on("up", (keyIndex: number) => {
        console.log(`Button ${keyIndex} up`);
        xkeys.setBacklight(keyIndex, null);
        if (ControlKeyIndex.Capture === keyIndex) {
          dispatch(dataActions.enableRearCamera(false));
        }
      });
      xkeys.on("jog", (index, value) => {
        console.log(`Jog #${index}: ${value}`);
      });
      xkeys.on("joystick", (index, value) => {
        console.log(`Joystick #${index}: ${JSON.stringify(value)}`);
      });
      // value here is an integer number
      xkeys.on("shuttle", (index, value) => {
        console.log(`Shuttle #${index}: ${value}`);
        dispatch(dataActions.setShuttleValue(value));
      });
      xkeys.on("tbar", (index, value) => {
        // console.log(`T-bar #${index}: ${value}`)
        if (timeoutIdGlobRef.current) {
          clearTimeout(timeoutIdGlobRef.current);
        }
        var dist = Math.floor((value / 255) * 100);
        dispatch(dataActions.setDistance({ distance: dist }));
        dispatch(
          dataActions.setCurrentLeashInput({
            currentLeashInput: dist.toFixed(2),
          })
        );
          dispatch(
            dataActions.sendDistance({ distance: dist, buttonId: ID_V_SLIDER })
          );
        timeoutIdGlobRef.current = setTimeout(() => {
          dispatch(
            dataActions.sendDistance({ distance: dist, buttonId: ID_V_SLIDER })
          );

          xkeys.setAllBacklights("red");
          setTimeout(() => {
            xkeys.setAllBacklights("blue");
          }, 500);
        }, 101);
      });
    },
    [dispatch, vehicleId]
  );

  const openDeviceRef = useRef(openDevice);
  
  const checkXkeysPanel = async() => {
    const devices = await getOpenedXKeysPanels();
    for (var i = 0; i < devices.length; i++) {
      if (devices[i].collections.length > 0) {
        if (devices[i].collections[0].outputReports?.length) {
          openDevice(devices[i]).catch(console.error);
          break;
        }
      }
    }
  }

  useEffect(() => {
    checkXkeysPanel();
  }, []);

  const selectHidDevice = async () => {
    if (currentXkeys) {
      console.log("Closing device");
      await currentXkeys.close();
      setCurrentXkeys(null);
    }
    let devices: HIDDevice[];
    // Prompt for a device
    try {
      console.log("Asking user for permissions...");
      devices = await requestXkeysPanels();
    } catch (error) {
      console.log(`No device access granted: ${error}`);
      return;
    }
    if (devices.length === 0) {
      console.log("No device was selected");
      return;
    }
    for (var i = 0; i < devices.length; i++){
      if (devices[i].collections.length > 0){
        if (devices[i].collections[0].outputReports?.length){
          console.log("Selecting device ", devices[i]);
          openDevice(devices[i]).catch(console.error);
          break;
        }
      }
    }
  };

  return { openDevice, selectHidDevice, currentXkeys };
}
