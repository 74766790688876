import { FC, ReactNode, useMemo } from "react";
import styled, { keyframes } from "styled-components";
import { XKey } from "../../constants/xkeys";
import { ReactComponent as EyeSvg } from "./assets/icons/eye.svg";
import { ReactComponent as NetworkSvg } from "./assets/icons/network.svg";

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
    /* filter: blur(12px); */
  }

  50% {
    /* filter: blur(2px); */
  }

  to {
    transform: scale(1);
    opacity: 0.85;
    /* filter: blur(0); */
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0.85;
    /* filter: blur(0); */
  }

  to {
    transform: scale(0);
    opacity: 0;
    /* filter: blur(8px); */
  }
`;

const Container = styled.div<{
  isOut?: boolean;
  wide?: boolean;
  color?: string;
}>`
  display: flex;
  place-items: center;
  place-content: center;
  column-gap: 32px;
  min-width: ${(props) => (props.wide ? 500 : 292)}px;
  padding: 0 ${(props) => (props.wide ? 84 : 0)}px;
  height: 292px;
  background: ${(props) => props.color ?? "#acacac"};
  opacity: ${(props) => (props.isOut ? 0 : 0.85)};
  border: 3px solid #cacaca;
  border-radius: 38px;
  animation: ${(props) => (props.isOut ? fadeOut : fadeIn)} 0.2s ease;
`;

const Text = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 82px;
  color: #4a4a4a;
`;

interface XKeyVisualStyle {
  icon?: FC<any>;
  text?: string;
  color?: string;
}

const XKeyVisualStyleMap: { [key in XKey]?: XKeyVisualStyle } = {
  Eye: {
    icon: EyeSvg,
  },
  Authorize: {
    color: "#90B89B",
    text: "Authorized",
  },
  Ready: {
    color: "#C6C87B",
    text: "Ready",
  },
  Network: {
    // color:
    text: "Reconnected",
    icon: NetworkSvg,
  }
};

export interface XKeyVisualProps {
  xkey: XKey;
  isOut?: boolean;
}

export const XKeyVisual: FC<XKeyVisualProps> = (props) => {
  const { isOut, xkey } = props;

  const styleData = XKeyVisualStyleMap[xkey] ?? {};

  const { text, icon, color } = styleData;
  const Icon = icon;

  return (
    <Container isOut={isOut} wide={!!text} color={color}>
      {!!Icon && <Icon />}
      {!!text && <Text>{text}</Text>}
    </Container>
  );
};

export default XKeyVisual;
