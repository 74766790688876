import styled, { css, keyframes } from "styled-components";
import { ReactComponent as ForwardArrowSvg } from "./assets/forwardArrow.svg";
import { ReactComponent as ParkingBrakeSvg } from "./assets/parkingBrake.svg";
import { ReactComponent as SmallArrowSvg } from "./assets/reverseArrow.svg";
import { ReactComponent as TurnSignalRightSvg } from "./assets/turnSignalRight.svg";

const blinkAnimation = keyframes`
    30% {
        /* opacity: 0.5; */
        /* color: #185f1f; */
        /* color: #7df689; */
        color: #97f8a0;
    }
`;

const TurnSignalIcon = styled(TurnSignalRightSvg)<{
    reverse?: boolean;
    enabled?: boolean;
  }>`
    ${(props) =>
      props.reverse
        ? css`
            transform: scaleX(-1);
          `
        : ``}
  
    ${(props) =>
      props.enabled
        ? css`
            color: #00b612;
            animation: ${blinkAnimation} 500ms ease infinite;
          `
        : css`
            color: #ffffff40;
          `}
  `;

const ForwardArrowIcon = styled(ForwardArrowSvg)<{ enabled?: boolean }>`
  margin-bottom: -4px;

  transition: color 100ms ease-in-out;

  ${(props) =>
    props.enabled
      ? css`
          color: #ffffff;
        `
      : css`
          color: #ffffff40;
        `}
`;

const SmallArrowIcon = styled(SmallArrowSvg)<{ enabled?: boolean }>`
  margin-top: -12px;

  transition: color 100ms ease-in-out;

  ${(props) =>
    props.enabled
      ? css`
          color: #ffffff;
        `
      : css`
          color: #ffffff40;
        `}
`;

const ParkingBrakeIcon = styled(ParkingBrakeSvg)<{ enabled?: boolean }>`
  ${(props) =>
    props.enabled
      ? css`
          color: #ff0000;
        `
      : css`
          color: #ffffff20;
        `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  flex: 0 0 36px;
`;

// uint8 PARKING_BRAKE_MOTOR_STATUS_OFF = 0
// uint8 PARKING_BRAKE_MOTOR_STATUS_ENGAGING = 1
// uint8 PARKING_BRAKE_MOTOR_STATUS_DISENGAGING = 2

// uint8 SHIFTER_STATE_NEUTRAL = 0
// uint8 SHIFTER_STATE_REVERSE = 1
// uint8 SHIFTER_STATE_FORWARD = 2

// uint8 TURN_SIGNAL_STATE_OFF = 0
// uint8 TURN_SIGNAL_STATE_LEFT = 1
// uint8 TURN_SIGNAL_STATE_RIGHT = 2
// uint8 TURN_SIGNAL_STATE_HAZARD = 3

export enum TurnSignalState {
  Off = 0,
  Left = 1,
  Right = 2,
  Hazard = 3,
}

export enum GearState {
  Neutral = 0,
  Reverse = 1,
  Forward = 2,
}

export interface DriveStateProps {
  gearState?: GearState;
  turnSignalLeftEnabled?: boolean;
  turnSignalRightEnabled?: boolean;
  parkingEnabled?: boolean;
  //   reverseEnabled?: boolean;
}

export function DriveState(props: DriveStateProps) {
  const {
    gearState,
    turnSignalLeftEnabled,
    parkingEnabled,
    turnSignalRightEnabled,
  } = props;

  return (
    <Container>
      <Row>
        <ForwardArrowIcon enabled={gearState === GearState.Forward} />
      </Row>
      <Row>
        <TurnSignalIcon reverse enabled={turnSignalLeftEnabled} />
        <ParkingBrakeIcon enabled={parkingEnabled} />
        <TurnSignalIcon enabled={turnSignalRightEnabled} />
      </Row>
      <Row>
        <SmallArrowIcon enabled={gearState === GearState.Reverse} />
      </Row>
    </Container>
  );
}
