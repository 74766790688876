export const RecordingStates = {
    Off: 1,
    RequestedOn: 2,
    RequestedOff: 3,
    Recording: 4,
} as const;

export type RecordingState = keyof typeof RecordingStates;

export const EventFlagStates = {
    Off: 1,
    Requested: 2
} as const;

export type EventFlagState = keyof typeof EventFlagStates;

export const RecordingStateIndex: { [key in RecordingState]: key } = {
    Off: "Off",
    RequestedOn: "RequestedOn",
    RequestedOff: "RequestedOff",
    Recording: "Recording",
} as const;

export const EventFlagStateIndex: { [key in EventFlagState]: key } = {
    Off: "Off",
    Requested: "Requested",
} as const