import { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import KVSVideo from "./Video";

import store, { useAppDispatch, useAppSelector } from "../store";
import { dataActions } from "../store/data/dataSlice";

export interface VehicleCameraViewProps {
  vehicleId: string;
}

export const VehicleCameraView: React.FC<VehicleCameraViewProps> = (props) => {
  const { vehicleId } = props;
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const socket = useAppSelector((state) => state.data.socket);
  // const vehicleId = useAppSelector((state) => state.data.vehicleId);

  useEffect(() => {
    dispatch(dataActions.intializeSocket({ vehicleId: vehicleId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId]);

  useEffect(() => {
    if (socket) {
      socket.onmessage = function (event) {
        console.log(`[message] Data received from server: ${event.data}`);

        try {
          const parsedData = JSON.parse(event.data);
          if (parsedData.vehicle_list !== undefined) {
            dispatch(dataActions.setVehicles(parsedData.vehicle_list));
            return;
          }

          if (
            parsedData.action === "change_state" &&
            parsedData.state_change === 6
          ) {
            dispatch(dataActions.setMode(6));
            dispatch(dataActions.setMessage("Teleassist Requested"));
            setTimeout(() => dataActions.setMessage(""), 4000);
          }

          if (parsedData.current_state !== undefined) {
            if (parsedData.vehicle_id !== vehicleId) return;
            if (parsedData.current_state > 10) {
              console.log("Not Supported Mode");
              return;
            }
            dispatch(dataActions.setMode(parsedData.current_state));
          }

          if (parsedData.abs_speed !== undefined) {
            if (parsedData.vehicle_id !== vehicleId) return;
            dispatch(
              dataActions.updateTelemetry({
                Speed: `${parsedData.abs_speed_mph} mph (${parsedData.abs_speed} m/s)`,
              })
            );
            const { latitude, longitude } = parsedData.geoposition;
            const yaw = parsedData.yaw;
            dispatch(dataActions.updateGPS([latitude, longitude]));
            dispatch(dataActions.updateYaw(yaw));
          }

          if (parsedData.throttle_percent_current !== undefined) {
            if (parsedData.vehicle_id !== vehicleId) return;
    
            dispatch(
              dataActions.updateTelemetry({
                Accel: parsedData.throttle_percent_current,
                Brake: parsedData.brake_value_current,
                Steering: parsedData.steering_position_current,
                gearState: parsedData.shifter_state,
                turnSignal: parsedData.turn_signal_state,
                parkingBrake: parsedData.parking_brake_engaged,
                blinkerLampOn: parsedData.blinker_lamp_on,
                // row:
              })
            );
          }

          if (parsedData.max_planned_speed !== undefined) {
            if (parsedData.vehicle_id !== vehicleId) return;
            dispatch(
              dataActions.updateRelay({
                MaxPlannedSpeed: `${parsedData.max_planned_speed_mph} mph (${parsedData.max_planned_speed} m/s)`,
                DelimitedSpeed: `${parsedData.delimited_speed_mph}`,
              })
            );
            dispatch(
              dataActions.updateMaxPlannedSpeedMph({
                maxPlannedSpeedMph: parsedData.max_planned_speed_mph,
              })
            );
          }

          if (parsedData.recording_id !== undefined){
            dispatch(
              dataActions.receiveRecordingStatus({
                recordingStatus: parsedData.recording_status,
              })
            );
          }

          if (parsedData.event_id !== undefined){
            dispatch(
              dataActions.receiveEventAcknowledgement({
                eventStatus: parsedData.flag_status,
              })
            );
          }
        } catch {
          // Plain Text Data
        }
      };

      socket.onclose = function (event) {
        if (event.wasClean) {
          console.log(
            `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
          );
        } else {
          console.log(`[close] Connection died; code=${event.code}`);
        }
        // dispatch(dataActions.intializeSocket({ vehicleId: vehicleId }));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, vehicleId]);

  return (
    <Provider store={store}>
      <div className={styles.screenContainer}>
        <div className={styles.flexContainer}>
          <div className={styles.leftContainer}>
            <KVSVideo />
          </div>
        </div>
      </div>
    </Provider>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenContainer: {
      position: "fixed",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: "lightgrey",
    },
    flexContainer: {
      display: "flex",
      height: "100%",
    },
    controlPanel: {
      width: "25%",
      maxWidth: 300,
      height: "100%",
    },
    leftContainer: {
      flex: 1,
      width: "max-content",
    },
  })
);

export default VehicleCameraView;
