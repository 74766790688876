export function useMph(value: string | number): number {
  if (typeof value === "number") {
    return value;
  }

  if (!value) {
    return 0;
  }

  const extractedValue = parseInt(value.split(" ")[0]);

  return extractedValue ?? 0;
}

export function useMphFloat(value: string | number): number {
  if (typeof value === "number") {
    return value;
  }

  if (!value) {
    return 0;
  }

  const extractedValue = parseFloat(value.split(" ")[0]);

  return extractedValue ?? 0;
}
