export const ControlKeyIndex = {
  Next: 1,
  //   Next: [1, 9],
  Authorize: 2,
  //   Authorize: [2, 10],
  Ready: 3,
  //   Ready: [3, 11],
  Flag: 4,
  /**
   * Toggles UI
   */
  Eye: 5,
  Record: 12,
  Capture: 13,
  List: 21,
  Network: 29,
  Stop: 41,
  //   Stop: [41, 42, 49, 50],
  ArrowUpLeft: 36,
  Cross: 44,
  ArrowUpRight: 52,
  SpeedometerMin: 38,
  SpeedometerMax: 46,
  PlusOne: 39,
  MinusOne: 40,
  PlusFive: 47,
  MinusFive: 48,
  UpControl: 70,
  LeftControl: 63,
  RevertBack: 71,
  RightControl: 79,
  BottomControl: 72,
} as const;

export type XKey = keyof typeof ControlKeyIndex;

export const ControlXKeys: XKey[] = Object.keys(ControlKeyIndex) as XKey[];

export const XKeyIndex: { [key in XKey]: key } = {
  Next: "Next",
  Authorize: "Authorize",
  Ready: "Ready",
  Flag: "Flag",
  Eye: "Eye",
  Record: "Record",
  Capture: "Capture",
  List: "List",
  Network: "Network",
  Stop: "Stop",
  ArrowUpLeft: "ArrowUpLeft",
  Cross: "Cross",
  ArrowUpRight: "ArrowUpRight",
  SpeedometerMin: "SpeedometerMin",
  SpeedometerMax: "SpeedometerMax",
  PlusOne: "PlusOne",
  MinusOne: "MinusOne",
  PlusFive: "PlusFive",
  MinusFive: "MinusFive",
  UpControl: "UpControl",
  LeftControl: "LeftControl",
  RevertBack: "RevertBack",
  RightControl: "RightControl",
  BottomControl: "BottomControl",
} as const;

export const ControlKeyboardKeyIndex: { [key in XKey]?: string } = {
  Next: "n",
  Authorize: "a",
  Ready: "z",
  Flag: "f",
  Record: "d",
  Eye: "h",
  Network: "c",
} as const;
