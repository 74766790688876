import { useEffect, useState, useRef, forwardRef } from "react";
import AWS from "aws-sdk";
import { SignalingClient } from "amazon-kinesis-video-streams-webrtc";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import { Auth } from "aws-amplify";

const WebRtc = forwardRef(({ channelName, onVideoLoaded }, ref) => {
  console.log("channelName", channelName);
  const state = { id: channelName };
  const classes = useStyles();
  const [stream, setStream] = useState("");
  const [LocalStream, setLocalStream] = useState("");
  const remoteViewRef = useRef(null);
  const remoteView = ref ?? remoteViewRef;

  const viewer = useRef({});

  const [streamLoaded, setStreamLoaded] = useState(false);

  // useEffect(() => {
  //   console.log('we create webrtc !!!')
  // }, [])

  useEffect(() => {
    startViewer();
    return () => {
      console.log("we stop viewer because we change channelName");
      stopViewer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelName]);

  useEffect(() => {
    if (!stream) {
      const exit = setTimeout(() => {
        console.log(
          "we stop viewer because exit from our webrtc stream (timeout 60 seconds)"
        );
        stopViewer();
      }, 60000);
      return () => clearTimeout(exit);
    }

    if (!!stream) {
      setStreamLoaded(true);
      onVideoLoaded?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream]);

  // useEffect(() => {}, []);

  const startViewer = async () => {
    if (!channelName) return;

    function getRandomClientId() {
      return Math.random().toString(36).substring(2).toUpperCase();
    }

    function onStatsReport(report) {
      // TODO: Publish stats
    }

    let res = await Auth.currentCredentials();

    const formValues = {
      region: "us-west-2",
      channelName: state.id,
      clientId: getRandomClientId(),
      sendVideo: false,
      sendAudio: true,
      openDataChannel: true,
      widescreen: true,
      fullscreen: true,
      useTrickleICE: true,
      natTraversalDisabled: false,
      forceTURN: false,
      accessKeyId: res.accessKeyId,
      endpoint: null,
      secretAccessKey: res.secretAccessKey,
      sessionToken: res.sessionToken,
    };

    const kinesisVideoClient = new AWS.KinesisVideo({
      region: formValues.region,
      accessKeyId: formValues.accessKeyId,
      secretAccessKey: formValues.secretAccessKey,
      sessionToken: formValues.sessionToken,
      correctClockSkew: true,
    });
    // Get signaling channel ARN
    console.log("Getting signaling channel ARN...");
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: formValues.channelName,
      })
      .promise();

    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    console.log("[VIEWER] Channel ARN: ", channelARN);

    // Get signaling channel endpoints:
    console.log("Getting signaling channel endpoints...");
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ["WSS", "HTTPS"],
          Role: "VIEWER", //roleOption.MASTER
        },
      })
      .promise();

    const endpointsByProtocol =
      getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
        (endpoints, endpoint) => {
          endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
          return endpoints;
        },
        {}
      );
    console.log("[VIEWER] Endpoints: ", endpointsByProtocol);

    const kinesisVideoSignalingChannelsClient =
      new AWS.KinesisVideoSignalingChannels({
        region: formValues.region,
        accessKeyId: formValues.accessKeyId,
        secretAccessKey: formValues.secretAccessKey,
        sessionToken: formValues.sessionToken,
        endpoint: endpointsByProtocol.HTTPS,
        correctClockSkew: true,
      });

    // Get ICE server configuration
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();
    const iceServers = [];
    if (!formValues.natTraversalDisabled && !formValues.forceTURN) {
      iceServers.push({
        urls: `stun:stun.kinesisvideo.${formValues.region}.amazonaws.com:443`,
      });
    }
    if (!formValues.natTraversalDisabled) {
      getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password,
        })
      );
    }
    console.log("[VIEWER] ICE servers: ", iceServers);

    // Create Signaling Client
    viewer.signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      clientId: formValues.clientId,
      role: "VIEWER",
      region: formValues.region,
      credentials: {
        accessKeyId: formValues.accessKeyId,
        secretAccessKey: formValues.secretAccessKey,
        sessionToken: formValues.sessionToken,
      },
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });

    const resolution = formValues.widescreen
      ? { width: { ideal: 1280 }, height: { ideal: 720 } }
      : { width: { ideal: 640 }, height: { ideal: 480 } };
    const constraints = {
      video: formValues.sendVideo ? resolution : false,
      audio: formValues.sendAudio,
    };
    const configuration = {
      iceServers,
      iceTransportPolicy: formValues.forceTURN ? "relay" : "all",
    };
    viewer.peerConnection = new RTCPeerConnection(configuration);

    if (formValues.openDataChannel) {
      viewer.dataChannel =
        viewer.peerConnection.createDataChannel("kvsDataChannel");
      viewer.peerConnection.ondatachannel = (event) => {
        event.channel.onmessage = console.log(event);
      };
    }

    

    // Poll for connection stats
    viewer.peerConnectionStatsInterval = setInterval(() => {
      // console.log('interval stats', viewer, viewer?.peerConnection, viewer.peerConnection?.getStats().then(console.log))
      viewer?.peerConnection?.getStats().then(onStatsReport);
    }, 1000);

    viewer.signalingClient.on("open", async () => {
      console.log("[VIEWER] Connected to signaling service");

      // Get a stream from the webcam, add it to the peer connection, and display it in the local view.
      // If no video/audio needed, no need to request for the sources.
      // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
      if (formValues.sendVideo || formValues.sendAudio) {
        try {
          viewer.localStream = await navigator.mediaDevices.getUserMedia(
            constraints
          );
          viewer.localStream
            .getTracks()
            .forEach((track) =>
              viewer.peerConnection.addTrack(track, viewer.localStream)
            );
        } catch (e) {
          console.error("[VIEWER] Could not find webcam");
          return;
        }
      }

      // Create an SDP offer to send to the master
      console.log("[VIEWER] Creating SDP offer");
      await viewer.peerConnection.setLocalDescription(
        await viewer.peerConnection.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
          // iceRestart: true,
        })
      );

      // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
      if (formValues.useTrickleICE) {
        console.log("[VIEWER] Sending SDP offer");
        viewer.signalingClient.sendSdpOffer(
          viewer.peerConnection.localDescription
        );
      }
      console.log("[VIEWER] Generating ICE candidates");
    });

    viewer.peerConnection.oniceconnectionstatechange =  async (ev) => {
      console.log(`[VIEWER NEW] STATE CHANGE ${viewer.peerConnection.iceConnectionState} channel : ${channelName}`)
      if (viewer.peerConnection.iceConnectionState === 'disconnected'){
        // await viewer.peerConnection.setLocalDescription(
        //   await viewer.peerConnection.createOffer({
        //     offerToReceiveAudio: true,
        //     offerToReceiveVideo: true,
        //     iceRestart: true,
        //   })
        // );

        // if (formValues.useTrickleICE) {
        //   console.log("[VIEWER] Sending SDP offer");
        //   viewer.signalingClient.sendSdpOffer(
        //     viewer.peerConnection.localDescription
        //   );
        // }
        stopViewer();
        startViewer();
      }
    };

    viewer.signalingClient.on("sdpAnswer", async (answer) => {
      console.log(
        "[VIEWER] Received SDP answer",
        answer,
        "viewer.peerConnection.setRemoteDescription"
      );
      await viewer.peerConnection.setRemoteDescription(answer);
    });

    viewer.signalingClient.on("iceCandidate", (candidate) => {
      console.log(
        "[VIEWER] Received ICE candidate",
        { candidate },
        "viewer.peerConnection.addIceCandidate"
      );
      viewer.peerConnection.addIceCandidate(candidate);
    });

    viewer.signalingClient.on("close", () => {
      console.log("[VIEWER] Disconnected from signaling channel");
    });

    viewer.signalingClient.on("error", (error) => {
      console.error("[VIEWER] Signaling client error: ", error);
    });

    // Send any ICE candidates to the other peer
    viewer.peerConnection.addEventListener("icecandidate", ({ candidate }) => {
      if (candidate) {
        console.log("[VIEWER] Generated ICE candidate");

        if (formValues.useTrickleICE) {
          console.log("[VIEWER] Sending ICE candidate");
          viewer.signalingClient.sendIceCandidate(candidate);
        }
      } else {
        console.log(
          "[VIEWER] All ICE candidates have been generated",
          channelName
        );

        if (!formValues.useTrickleICE) {
          console.log("[VIEWER] Sending SDP offer");
          viewer.signalingClient.sendSdpOffer(
            viewer.peerConnection.localDescription
          );
        }
      }
    });

    viewer.peerConnection.onaddstream = (event) => {
      console.log("[VIEWER] Received remote track");
      if (remoteView.current.srcObject) {
        return;
      }
      viewer.remoteStream = event.stream;
      remoteView.current.srcObject = viewer.remoteStream;
      setStream(viewer.remoteStream);
    };

    console.log("[VIEWER] Starting viewer connection", channelName);
    viewer.signalingClient.open();
  };

  const stopViewer = () => {
    console.log(channelName, "stopViewer()");

    if (viewer.signalingClient) {
      viewer.signalingClient.close();
      viewer.signalingClient = null;
    }

    if (viewer.peerConnection) {
      viewer.peerConnection.close();
      viewer.peerConnection = null;
    }

    if (viewer.localStream) {
      viewer.localStream.getTracks().forEach((track) => track.stop());
      viewer.localStream = null;
    }

    if (viewer.remoteStream) {
      viewer.remoteStream.getTracks().forEach((track) => track.stop());
      viewer.remoteStream = null;
    }

    if (viewer.peerConnectionStatsInterval) {
      clearInterval(viewer.peerConnectionStatsInterval);
      viewer.peerConnectionStatsInterval = null;
    }

    if (LocalStream) {
      setLocalStream(null);
    }

    if (remoteView.current) {
      remoteView.current.srcObject = null;
    }

    if (viewer.dataChannel) {
      viewer.dataChannel = null;
    }
  };

  return (
    <>
      {/* {!stream && <LinearProgress color="primary" />} */}
      <video
        className={classes.outputView}
        ref={remoteView}
        autoPlay
        playsInline
        // onLoadedData={onVideoLoaded}
        // onLoad={() => {
        //   console.log("LOADED");
        // }}
      />
    </>
  );
});

WebRtc.displayName = "WebRtc";

const useStyles = makeStyles((theme) => ({
  outputView: {
    objectFit: "cover",
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  buttonContainer: {
    position: "absolute",
    width: "100%",
    bottom: 0,
  },
  icon: {
    width: 50,
  },
  muteIcon: {
    width: 75,
  },
}));

export default WebRtc;
