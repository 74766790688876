import { FC, useEffect } from "react";
import styled from "styled-components";

import VehicleImagePng from "./assets/vehicle.png";

import { ReactComponent as ComplexLogoSvg } from "./assets/complex-logo.svg";
import { ReactComponent as FactionLogoSvg } from "./assets/faction-logo.svg";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Auth } from "aws-amplify";

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #232323;
  padding: 0 20px;
  justify-content: space-between;
`;

const Main = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  max-width: 1100px;
  width: 100%;
  min-height: 20px;
  /* background-color: gray; */
`;

const FormCol = styled.form`
  flex: 0 0 275px;
  /* background-color: green; */
`;
const ImageCol = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 64px;
  /* background-color: blue; */
`;

const Image = styled.img`
  max-width: 100%;
  object-fit: contain;
  image-rendering: crisp-edges;
  image-rendering: -webkit-optimize-contrast;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 40px;
  margin-bottom: 12px;
`;

const CopyrightText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #989898;
`;

const Input = styled.input`
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;

  color: #000000;

  ::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }

  padding: 4px 14px;
`;

const LinkButton = styled.a`
  color: black;
  margin-left: auto;
  cursor: pointer;
`;

const Button = styled.button`
  display: flex;
  place-items: center;
  place-content: center;

  background-color: black;
  border: 2px solid rgba(88, 88, 88);
  border-radius: 10px;
  outline: none;
  box-shadow: none;

  &:active,
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  padding: 3px 0;

  cursor: pointer;
`;

interface Fields {
  name: string;
  password: string;
}

export interface SignInProps {
  onSignIn?: (result?: any) => any;
}

export const SignInPage: FC<SignInProps> = (props) => {
  const { onSignIn } = props;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Fields>({
    defaultValues: { name: "", password: "" },
  });

  const onSubmit: SubmitHandler<Fields> = ({ name, password }) => {
    return Auth.signIn({
      username: name,
      password,
    })
      .then((result) => {
        onSignIn?.(result);
      })
      .catch((err) => {
        console.log({ errorOnSignInAttempt: err, errorMessage: err?.message });
      });
  };

  return (
    <Layout>
      <Main>
        <FormCol>
          <ComplexLogoSvg />
          <InputsContainer>
            <Controller
              control={control}
              name="name"
              render={({ field }) => {
                return (
                  <Input placeholder="Name" spellCheck={false} {...field} />
                );
              }}
            />
            <Controller
              control={control}
              name="password"
              render={({ field }) => {
                return (
                  <Input
                    placeholder="Password"
                    spellCheck={false}
                    type="password"
                    {...field}
                  />
                );
              }}
            />

            <LinkButton>Forgot Password</LinkButton>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              Log In
            </Button>
          </InputsContainer>
          <CopyrightText>Powered by DriveLink&reg;</CopyrightText>
          <CopyrightText>
            Copyright &copy; Faction Technology Inc.
          </CopyrightText>
        </FormCol>
        <ImageCol>
          <Image src={VehicleImagePng} alt="vehicle" />
        </ImageCol>
      </Main>
      <Header>
        <FactionLogoSvg />
      </Header>
    </Layout>
  );
};
