import {
  cloneElement,
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import WebRtcNoTypes from "../../components/Webrtc/index";
import { useAppDispatch, useAppSelector } from "../../store";
import { ID_V_SLIDER, modeMap } from "../../constants";
import styled, { css, CSSProperties, keyframes } from "styled-components";
import { Telemetry } from "../../components/Telemetry";
import { useXkeys } from "../../hooks/xkeys";
import { ReactComponent as LogoTrademarkSvg } from "./logo-trademark.svg";
import { ReactComponent as BlankLogoTrademarkSvg } from "./blank-logo-trademark.svg";
import { dataActions } from "../../store/data/dataSlice";
import { ControlKeyboardKeyIndex, XKeyIndex } from "../../constants/xkeys";
import { XKeyVisual } from "../../components/XKeyVisual";
import { ReactComponent as FlagIconSvg } from "./flag-icon.svg"
import { ReactComponent as RecordIconSvg } from "./rec-icon.svg";
import {
  FullscreenSlider,
  Slide,
} from "../../components/FullscreenSlider/FullscreenSlider";
import { AnimatePresence, motion } from "framer-motion";
import { LinearProgress } from "@material-ui/core";
import Map from "../../components/Map/Map";
import { EventFlagStates, RecordingState, RecordingStateIndex } from "../../constants/events";
import { EventFlagState, EventFlagStateIndex } from "../../constants/events";

const WebRtc = WebRtcNoTypes as FC<any>;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  place-items: center;
  padding: 12px;
`;

const FootageContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin: 0 auto;
  width: 60%;
  justify-content: center;
  align-items: center;
`;

const Footage = styled(motion.div) <{
  label?: string;
  labelAlign?: CSSProperties["textAlign"];
  blank?: boolean;
}>`
  /* flex: 1 100px; */
  background-color: ${(props) =>
    props.blank ? "#838383" : "rgba(0, 0, 0, 0.25)"};

  aspect-ratio: 1.75;
  /* width: 100%; */
  height: 130px;

  @media (min-width: 1200px) {
    height: 170px;
  }

  @media (min-width: 1480px) {
    height: 200px;
  }
  /* max-height: 170px; */

  position: relative;

  &:after {
    content: "${(props) => props.label}";
    display: block;
    width: 100%;
    position: absolute;
    bottom: -2rem;
    color: white;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-shadow: 1px 1px 2px #000000bf;
    text-align: ${(props) => props.labelAlign ?? "left"};
  }
`;

const Label = styled.p<{ labelAlign?: CSSProperties["textAlign"] }>`
  display: block;
  width: 100%;
  color: white;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-shadow: 1px 1px 2px #000000bf;
  text-align: ${(props) => props.labelAlign ?? "left"};
`;

const TelemetryWrapper = styled.div<{ blank?: boolean }>`
  display: flex;
  flex-direction: column;

  position: absolute;
  right: 20px;
  top: 20px;

  user-select: none;
  cursor: pointer;

  ${(props) =>
    props.blank
      ? `
      height: 212px;
      width: 155px;
      background: #838383;
  `
      : ``}
`;

const MapContainer = styled.div<{ blank?: boolean }>`
  position: absolute;
  left: 12px;
  top: 12px;
  height: ${(props) => (props.blank ? "auto" : "212px")};
  width: ${(props) => (props.blank ? "15%" : "212px")};
  background-color: ${(props) =>
    props.blank ? "#838383" : "rgba(0, 0, 0, 0.15)"};

  display: flex;
  place-items: center;
  place-content: center;

  ${(props) =>
    props.blank
      ? `
  aspect-ratio: 1;
  max-width: 350px;
  `
      : ``}
`;

const RootContainer = styled.div`
  /* max-width: calc(177.78vh - 355.56px); */
  /* max-height: calc(100vh - 200px); */
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  /* margin: auto; */
  background-color: black;
`;

const breatheAnimation = keyframes`
 0% { opacity: 1}
 50% { opacity: 0.8}
 100% { opacity:1 }
`;

const VideoContainer = styled.div<{ blank?: boolean }>`
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* bottom: 0; */
  /* right: 0; */
  background-color: #939393;
  /* background-color: ${(props) => (props.blank ? " #939393" : "#207a20")}; */
  aspect-ratio: 16/9;
  margin: auto;
  width: 100%;
  /* height: 100%; */
  position: relative;

  ${(props) =>
    props.blank
      ? css`
          animation-name: ${breatheAnimation};
          animation-duration: 2s;
          animation-timing-function: ease-in-out;
          /* animate- */
          animation-iteration-count: infinite;
        `
      : ``}
`;

const ControlsContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 12;
  left: 12;
  bottom: 12;
  right: 12;
  z-index: 20;
`;

const Trademark = styled(LogoTrademarkSvg)<{
    showUi: boolean 
  }>`
  /* left: auto; */
  /* right: auto; */
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  width: 184px;
  /* width: 316px; */
  ${(props) => (props.showUi? css`top: 870px;` : css`top: 8px;`)}
  z-index: 20;
  /* top: 16px; */
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const TelemetryLabels = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  row-gap: 6px;
`;

const FlagIcon = styled(FlagIconSvg)<{
    eventFlagState: EventFlagState;
  }>`
    position: absolute;
    right: 50px;
    bottom: 50px;
    ${(props) => (props.eventFlagState === EventFlagStateIndex.Off ? 
                              css`visibility: hidden;
                                  opacity: 0;
                                  transition: visibility 1.0s linear, opacity 1.0s linear;
                              ` 
                              : css`
                                  opacity: 100;
                                  transition: opacity 1.0s linear;
                              `)}

  `;

const recordBlinkAnimation = keyframes`
    30% {
        opacity: 0.2;
    }
`;

const RecordIcon = styled(RecordIconSvg)<{
    recordingState: RecordingState;
  }>`
    position: absolute;
    left: 50px;
    bottom: 50px;

    ${(props) => (props.recordingState === RecordingStateIndex.Off ? 
                          css`visibility: hidden;
                            opacity: 0;
                            transition visibility 0.3s linear, opacity 0.3s linear
                          ` 
                          : css``)}
    ${(props) => (props.recordingState === RecordingStateIndex.Recording ? css`animation: ${recordBlinkAnimation} 2000ms ease infinite;` : css``)}
`;

const DIGITS_KEYS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"] as const;

const KEYBOARD_THANDLE_KEY_PRESS_DELAY_MS = 500;

const BlankContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 64px;
  padding-top: 5%;
  height: 100%;
  width: 100%;
  place-items: center;
  place-content: center;
`;

const ConnectingToFeedMessage = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 90px;
  color: #e2e2e2;
`;

const BlankTrademark = styled(BlankLogoTrademarkSvg)``;

const XKeyVisualizationContainer = styled.div`
  position: absolute;
  pointer-events: none;
  inset: 0;
  display: flex;
  place-items: center;
  place-content: center;
  z-index: 30;
`;

interface VideoViewProps {
  vehicleChannel?: string;
  onVideoLoaded?: () => any;
  needsPathOverlay?: boolean;
}

const VideoView = forwardRef<HTMLVideoElement, VideoViewProps>(
  ({ vehicleChannel, onVideoLoaded, needsPathOverlay = false }, ref) => {
    console.log({ vehicleChannel });
    const webRtcNode = useMemo(() => {
      if (!vehicleChannel) {
        return null;
      }
      return (
        <WebRtc
          ref={ref}
          channelName={vehicleChannel ? vehicleChannel : ""}
          onVideoLoaded={onVideoLoaded}
        />
      );
    }, [vehicleChannel]);

    return (
      <>
        {webRtcNode}
      </>
    );
  }
);
const Sl = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 100%;
`;

const MapLabels = styled.div`
  color: white;
  font-size: 24px;
  text-shadow: 0.5px 0.5px black;
`;

const VideoCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  opacity: 1;
`;

const KVSVideo = () => {
  const styles = useStyles();
  const currentMode = useAppSelector((state) => state.data.appMode);
  const socket = useAppSelector((state) => state.data.socket);
  const vehicleId = useAppSelector((state) => state.data.vehicleId);
  const vehicles = useAppSelector((state) => state.data.vehicles);
  const pressedXKey = useAppSelector((state) => state.data.pressedXKey);
  const shuttle = useAppSelector((state) => state.data.shuttle);
  const rearCameraEnabled = useAppSelector((state) => state.data.rearCameraEnabled)
  const recordingState = useAppSelector((state) => state.data.recordingState)
  const eventFlagState = useAppSelector((state) => state.data.eventFlagState)
  const message = useAppSelector((state) => state.data.message);

  const showUI = useAppSelector((state) => state.data.appShowUI);

  const dispatch = useAppDispatch();

  const [, setIsInputEnabled] = useState(false);
  const [hideCursor, setHideCursor] = useState(false);

  const { selectHidDevice, currentXkeys } = useXkeys();

  const enableInput = useCallback(() => {
    selectHidDevice()
      .then(() => {
        console.log("device selected or requested");
        setIsInputEnabled(true);
      })
      .catch((err) => console.log("device select or request error:", err));

    setTimeout(() => {
      setHideCursor(true);
    }, 2000);
  }, [selectHidDevice]);

  const keyJustPressedRef = useRef<string | null>(null);

  const keyPressedTO = useRef<ReturnType<typeof setTimeout>>();

  const [visualPressed, setVisualPressed] = useState(false);
  const [visualFadeOut, setVisualFadeOut] = useState(false);

  const xkeyVisualFadeOutTO = useRef<ReturnType<typeof setTimeout>>();
  const xkeyVisualFadeOutTOInternal = useRef<ReturnType<typeof setTimeout>>();
  const xkeyVisualTO = useRef<ReturnType<typeof setTimeout>>();

  const resetPressedXkey = () => {
    dispatch(dataActions.resetPressedXKey());
  };

  const resetPressedXKeyVisuals = () => {
    setVisualFadeOut(false);
    setVisualPressed(false);

    if (keyPressedTO.current) {
      clearTimeout(keyPressedTO.current);
    }

    if (xkeyVisualFadeOutTO.current) {
      clearTimeout(xkeyVisualFadeOutTO.current);
    }

    if (xkeyVisualFadeOutTOInternal.current) {
      clearTimeout(xkeyVisualFadeOutTOInternal.current);
    }

    if (xkeyVisualTO.current) {
      clearTimeout(xkeyVisualTO.current);
    }

    // resetPressedXkey();
  };

  const XKeyVisualTimeoutMS = 500;

  const setXKeyVisualTO = () => {
    setVisualPressed(true);
    xkeyVisualTO.current = setTimeout(() => {
      setVisualFadeOut(true);
      xkeyVisualFadeOutTO.current = setTimeout(() => {
        setVisualPressed(false);
        xkeyVisualFadeOutTOInternal.current = setTimeout(() => {
          setVisualFadeOut(false);
          resetPressedXkey();
        }, 50);
      }, 200);
    }, XKeyVisualTimeoutMS);
  };

  useEffect(() => {
    console.log({ pressedXKey });
    resetPressedXKeyVisuals();
    setXKeyVisualTO();
  }, [pressedXKey]);

  const glanceKeyPressedTO = useRef<ReturnType<typeof setTimeout>>();

  const [keyPressed, setKeyPressed] = useState<"none" | "left" | "right" | "thermal" | "rear">(
    "none"
  );
  console.log({ keyPressed });
  const setLeashToRef = useRef<ReturnType<typeof setTimeout>>();

  // TODO add socket is conected check before sending any messages to socket
  useEffect(() => {
    const handler = (ev: KeyboardEvent) => {
      resetPressedXkey();
      const key = ev.key;
      if (key === ControlKeyboardKeyIndex.Eye) {
        dispatch(dataActions.toggleAppUI());
      } else if (key === ControlKeyboardKeyIndex.Authorize) {
        dispatch(dataActions.sendAuthorize());
      } else if (key === ControlKeyboardKeyIndex.Flag) {
        dispatch(dataActions.sendEventRequest());
      } else if (key === ControlKeyboardKeyIndex.Record) {
        dispatch(dataActions.sendRecordingRequest()); 
      } else if (key === ControlKeyboardKeyIndex.Ready) {
        dispatch(dataActions.sendReady());
      } else if (DIGITS_KEYS.includes(key as any)) {
        console.log("digit pressed");
        if (keyPressedTO.current) {
          clearTimeout(keyPressedTO.current);
          keyPressedTO.current = undefined;
        }
        const to = setTimeout(() => {
          keyJustPressedRef.current = null;
        }, KEYBOARD_THANDLE_KEY_PRESS_DELAY_MS);
        keyPressedTO.current = to;
        if (keyJustPressedRef.current) {
          const newDist = parseFloat(`${keyJustPressedRef.current}${key}`);
          console.log({ newDist });

          if (setLeashToRef.current) {
            clearTimeout(setLeashToRef.current);
          }

          const newLeashValue = newDist;
          dispatch(dataActions.setDistance({ distance: newLeashValue }));
          dispatch(
            dataActions.setCurrentLeashInput({
              currentLeashInput: newLeashValue.toFixed(2),
            })
          );
            dispatch(
              dataActions.sendDistance({
                distance: newLeashValue,
                buttonId: ID_V_SLIDER,
              })
            );
          setLeashToRef.current = setTimeout(() => {
            
            console.log(
              `Sent leash command to vehicle ${Math.floor(newLeashValue)}`
            );
            currentXkeys?.setAllBacklights("red");
            setTimeout(() => {
              currentXkeys?.setAllBacklights("blue");
            }, 0);
          }, 0);

        }

        keyJustPressedRef.current = key;
      } else if (ev.key === "[") {
        setKeyPressed("left");
      } else if (ev.key === "]") {
        setKeyPressed("right");
      } else if (ev.key === "\\") {
        setKeyPressed("thermal");
      } else if (ev.key === "p") {
        setKeyPressed("rear");
      }
      else if (key === ControlKeyboardKeyIndex.Network) {
        dispatch(dataActions.intializeSocket({ vehicleId: vehicleId }));
        // dispatch(dataActions.setPressedXKey(XKeyIndex.Network));
      }
    };

    const keyUpHandler = (ev: KeyboardEvent) => {
      if (ev.key === "[" || ev.key === "]" || ev.key === "\\" || ev.key === "p") {
        setKeyPressed("none");
      }
    };

    window.addEventListener("keydown", handler);
    window.addEventListener("keyup", keyUpHandler);

    return () => {
      window.removeEventListener("keydown", handler);
      window.removeEventListener("keyup", keyUpHandler);
    };
  }, [dispatch, currentXkeys, vehicleId]);

  const status = useMemo(() => {
    return modeMap[currentMode] ?? "";
  }, [currentMode]);

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isVideoLoadedLeft, setIsVideoLoadedLeft] = useState(true);
  const [isVideoLoadedRight, setIsVideoLoadedRight] = useState(true);
  const [isVideoLoadedRear, setIsVideoLoadedRear] = useState(true);
  const [isVideoLoadedThermal, setIsVideoLoadedThermal] = useState(true);

  const vehicleChannel = vehicleId ? vehicles[vehicleId] : null;

  const xkeyVisualNode = useMemo(() => {
    console.log({ visualPressed, pressedXKey });
    return (
      visualPressed &&
      !!pressedXKey &&
      !!showUI && <XKeyVisual isOut={visualFadeOut} xkey={pressedXKey} />
    );
  }, [visualPressed, visualFadeOut, pressedXKey, showUI]);

  console.log({ keyPressed });

  const videoLeftRef = useRef<HTMLVideoElement>(null);
  const videoMainRef = useRef<HTMLVideoElement>(null);
  const videoRightRef = useRef<HTMLVideoElement>(null);
  const videoRearRef = useRef<HTMLVideoElement>(null);
  const videoThermalRef = useRef<HTMLVideoElement>(null);

  const leftCameraCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const rearCameraCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const rightCameraCanvasRef = useRef<HTMLCanvasElement | null>(null);

  const drawVideoOutputInCanvas = (
    canvas: HTMLCanvasElement,
    video: HTMLVideoElement
  ) => {
    const context = canvas.getContext("2d");
    const cw = Math.floor(canvas.clientWidth);
    const ch = Math.floor(canvas.clientHeight);
    canvas.width = cw;
    canvas.height = ch;

    if (!context) {
      return;
    }

    context.drawImage(video, 0, 0, cw, ch);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!leftCameraCanvasRef?.current || !videoLeftRef?.current) {
        return;
      }
      drawVideoOutputInCanvas(
        leftCameraCanvasRef.current,
        videoLeftRef.current
      );
    }, 75);

    return () => {
      clearInterval(interval);
    };
  }, [isVideoLoadedLeft]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!rearCameraCanvasRef?.current || !videoRearRef?.current) {
        return;
      }

      drawVideoOutputInCanvas(
        rearCameraCanvasRef.current,
        videoRearRef.current
      );
    }, 75);

    return () => {
      clearInterval(interval);
    };
  }, [isVideoLoadedRear]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!rightCameraCanvasRef?.current || !videoRightRef?.current) {
        return;
      }
      drawVideoOutputInCanvas(
        rightCameraCanvasRef.current,
        videoRightRef.current
      );
    }, 75);

    return () => {
      clearInterval(interval);
    };
  }, [isVideoLoadedRight]);

  const viewViewNodeLeft = useMemo(() => {
    return (
      <VideoView
        ref={videoLeftRef}
        vehicleChannel={vehicleChannel ? vehicleChannel + "-left" : undefined}
        onVideoLoaded={setIsVideoLoadedLeft.bind(this, true)}
      />
    );
  }, [vehicleChannel]);

  const viewViewNode = useMemo(() => {
    return (
      <VideoView
        ref={videoMainRef}
        vehicleChannel={vehicleChannel ? vehicleChannel : undefined}
        onVideoLoaded={setIsVideoLoaded.bind(this, true)}
        needsPathOverlay
      />
    );
  }, [vehicleChannel]);

  const viewViewNodeRight = useMemo(() => {
    return (
      <VideoView
        ref={videoRightRef}
        vehicleChannel={vehicleChannel ? vehicleChannel + "-right" : undefined}
        onVideoLoaded={setIsVideoLoadedRight.bind(this, true)}
      />
    );
  }, [vehicleChannel]);

  const viewViewNodeRear = useMemo(() => {
    return (
      <VideoView
        ref={videoRearRef}
        vehicleChannel={vehicleChannel ? vehicleChannel + "-rear" : undefined}
        onVideoLoaded={setIsVideoLoadedRear.bind(this, true)}
      />
    );
  }, [vehicleChannel]);

  const viewViewNodeThermal = useMemo(() => {
    return (
      <VideoView
        ref={videoThermalRef}
        vehicleChannel={vehicleChannel ? vehicleChannel + "-thermal" : undefined}
        onVideoLoaded={setIsVideoLoadedThermal.bind(this, true)}
      />
    );
  }, [vehicleChannel]);

  console.log({ isVideoLoaded });

  const currentSlide = useMemo(() => {
    if (shuttle >= 3) {
      return "right";
    }
    if (shuttle <= -3) {
      return "left";
    }
    if (rearCameraEnabled) {
      return "rear";
    }

    return keyPressed;
  }, [keyPressed, shuttle, rearCameraEnabled]);


  if (!isVideoLoaded) {
    return (
      <RootContainer>
        <VideoContainer blank>
          <ControlsContainer>
            <TopBar>
              {/* <MapContainer blank /> */}
              <MapContainer>
                <Map />
              </MapContainer>
              <FootageContainer>
                <Footage blank />
                <Footage blank />
                <Footage blank />
              </FootageContainer>
              <TelemetryWrapper onClick={enableInput}>
                <Telemetry />
                <TelemetryLabels>
                  <Label labelAlign="right">{vehicleId}</Label>
                  <Label labelAlign="right">{status || "-"}</Label>
                </TelemetryLabels>
              </TelemetryWrapper>
            </TopBar>
          </ControlsContainer>
          <FlagIcon eventFlagState={eventFlagState}/>
          <RecordIcon recordingState={recordingState}/>
          {viewViewNode}
          <BlankContentContainer>
            <BlankTrademark />
            <ConnectingToFeedMessage>
              Connecting to video feed...
            </ConnectingToFeedMessage>
          </BlankContentContainer>
          <XKeyVisualizationContainer>
            {xkeyVisualNode}
          </XKeyVisualizationContainer>
        </VideoContainer>
      </RootContainer>
    );
  }

  return (
    <RootContainer style={{ cursor: hideCursor ? "none" : undefined }}>
      <VideoContainer>
        {!isVideoLoaded && <LinearProgress color="primary" />}

        <FullscreenSlider
          numberOfSlides={5}
          slide={{ none: 1, left: 0, right: 2, rear: 3, thermal: 4 }[currentSlide]}
        >
          <Slide>{viewViewNodeLeft}</Slide>
          <Slide>{viewViewNode}</Slide>
          <Slide>{viewViewNodeRight}</Slide>
          <Slide>{viewViewNodeRear}</Slide>
          <Slide>{viewViewNodeThermal}</Slide>
        </FullscreenSlider>
        {currentSlide == "none" && (
          <>
          <ControlsContainer>
            <TopBar>
              <Trademark showUi={showUI}/>
              {showUI && (
                <>
                  <MapContainer>
                    <Map />
                  </MapContainer>
                  <FootageContainer>
                    <Footage
                      label={vehicleId}
                    >
                      <VideoCanvas ref={leftCameraCanvasRef} />
                    </Footage>
                    <Footage
                    >
                      <VideoCanvas ref={rearCameraCanvasRef} />

                    </Footage>
                    <Footage
                      label={status || "-"}
                    >
                      <VideoCanvas ref={rightCameraCanvasRef} />
                    </Footage>
                  </FootageContainer>
                </>
              )}
              <TelemetryWrapper onClick={enableInput}>
                <Telemetry />
                {!showUI && (
                  <TelemetryLabels>
                    <Label labelAlign="right">{vehicleId}</Label>
                    <Label labelAlign="right">{status || "-"}</Label>
                  </TelemetryLabels>
                )}
              </TelemetryWrapper>
            </TopBar>
            {!!message && <h1 className={styles.message}>message</h1>}
          </ControlsContainer>
          </>
          )}
        <FlagIcon eventFlagState={eventFlagState}/>
        <RecordIcon recordingState={recordingState}/>
        <XKeyVisualizationContainer>
          {xkeyVisualNode}
        </XKeyVisualizationContainer>
      </VideoContainer>
    </RootContainer >
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    videoRootContainer: {
      maxWidth: "calc(177.78vh - 355.56px)",
      maxHeight: "calc(100vh - 200px)",
      margin: "auto",
    },
    videoRoot: {
      maxWidth: "calc(177.78vh - 355.56px)",
      maxHeight: "calc(100vh - 200px)",
      width: "100%",
      paddingTop: "56.25%",
      position: "relative",
    },
    videoContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "green",
    },
    controlContainer: {
      position: "absolute",
      top: 12,
      left: 12,
      bottom: 12,
      right: 12,
    },
    whiteText: {
      color: "white",
    },
    cancelButton: {
      backgroundColor: "#C03023",
      color: "white",
      fontWeight: 600,
      position: "absolute",
      left: 0,
      bottom: 0,

      "&:hover": {
        backgroundColor: "#BD3023",
      },
      "&:active": {
        backgroundColor: "#BA3023",
      },
    },
    horizontalSlider: {
      position: "absolute",
      left: "calc(50% - 150px)",
      bottom: 0,
      zIndex: 100,
    },
    verticalSlider: {
      position: "absolute",
      top: "calc(50% - 150px)",
      left: 0,
      height: 300,
    },
    message: {
      textAlign: "center",
      marginTop: 0,
      color: "#AC0000",
      textShadow: "0.5px 0.5px black",
    },
    smallScreensContainer: {
      width: "100%",
      height: "100%",
      flexGrow: 1,
      background: "red",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    smallScreen: {
      flex: 1,
      height: "100%",
      backgroundColor: "#8d8d8d",
      border: "1px solid grey",
    },
  })
);

export default KVSVideo;
