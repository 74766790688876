import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GearState, TurnSignalState } from "../../components/Telemetry/DriveState";
import {
  DEFAULT_DISTANCE,
  DEFAULT_LATERAL_OFFSET,
  DEFAULT_LONGITUDINAL_OFFSET,
  MAX_LATERAL_OFFSET,
  MAX_LONGITUDINAL_OFFSET,
  MIN_LATERAL_OFFSET,
  MIN_LONGITUDINAL_OFFSET,
} from "../../constants";
import { XKey, XKeyIndex } from "../../constants/xkeys";
import { RecordingState, EventFlagState, RecordingStateIndex, EventFlagStateIndex } from "../../constants/events"
interface DataSliceState {
  appMode: number;
  socket: WebSocket | null;
  vehicleId: string;
  lateralOffset: number;
  longitudinalOffset: number;
  distance: number;
  message: string;
  telemetry: {
    Speed: string;
    Accel: number;
    Brake: number;
    Steering: number;
    gearState: GearState;
    turnSignal: TurnSignalState;
    parkingBrake: boolean;
    blinkerLampOn: boolean;
  };
  relay: any;
  gps: number[];
  yaw: number;
  vehicles: any;
  currentLeashInput: any;
  maxPlannedSpeedMph: number;
  videoRootHeight: number;
  videoRootWidth: number;
  shuttle: number;
  rearCameraEnabled: boolean;

  appShowUI: boolean;

  pressedXKey?: XKey | null;
  recordingState: RecordingState;
  eventFlagState: EventFlagState;
  lastSentLeashTime: number;
}

const initialState: DataSliceState = {
  appMode: 0,
  socket: null,
  vehicleId: "",
  vehicles: {},
  lateralOffset: DEFAULT_LATERAL_OFFSET,
  longitudinalOffset: DEFAULT_LONGITUDINAL_OFFSET,
  distance: DEFAULT_DISTANCE,
  message: "",
  telemetry: {
    Speed: "0",
    Accel: 0,
    Brake: 0,
    Steering: 0,
    gearState: 0,
    turnSignal: 0,
    parkingBrake: false,
    blinkerLampOn: false,
  },
  relay: {
    MaxPlannedSpeed: "0",
    DelimitedSpeed: "0",
  },
  gps: [],
  yaw: 0,
  currentLeashInput: "0",
  maxPlannedSpeedMph: 0,
  videoRootHeight: 0.0,
  videoRootWidth: 0.0,
  shuttle: 0,
  rearCameraEnabled: false,

  appShowUI: true,

  pressedXKey: null,
  recordingState: RecordingStateIndex.Off,
  eventFlagState: EventFlagStateIndex.Off,
  lastSentLeashTime: Date.now()
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setMode: (state, { payload }: PayloadAction<number>) => {
      state.appMode = payload;
    },
    setMessage: (state, { payload }: PayloadAction<string>) => {
      state.message = payload;
    },
    setVehicleId: (state, { payload }: PayloadAction<string>) => {
      state.vehicleId = payload;

      state.appMode = 0;
      state.lateralOffset = DEFAULT_LATERAL_OFFSET;
      state.longitudinalOffset = DEFAULT_LONGITUDINAL_OFFSET;
      state.distance = DEFAULT_DISTANCE;
      state.message = "";
      state.telemetry = {
        ...initialState.telemetry,
        Speed: "0",
        Accel: 0,
        Brake: 0,
        Steering: 0,
      };
      state.relay = {
        MaxPlannedSpeed: "0",
        DelimitedSpeed: "0",
      };
      state.currentLeashInput = "0";
      state.maxPlannedSpeedMph = 0;
    },
    intializeSocket: (state, { payload }: PayloadAction<any>) => {
      let socket = new WebSocket(process.env.REACT_APP_BACKEND_URL || "");

      socket.onopen = function (e) {
        socket.send(
          JSON.stringify({
            action: "getVehicleIds",
          })
        );
        socket.send(
          JSON.stringify({
            action: "setVehicleId",
            vehicle_id: payload.vehicleId,
          })
        );
      };

      socket.onmessage = function (event) {
        try {
          const parsedData = JSON.parse(event.data);
          if (parsedData.action === "change_state" && parsedData.action === 6) {
            state.appMode = 6;
          }
        } catch {
          // Plain Text Data
        }
      };

      socket.onclose = function (event) {
        if (event.wasClean) {
          console.log(
            `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
          );
        } else {
          console.log(`[close] Connection died; code=${event.code}`);
        }
      };

      socket.onerror = function (error) {
        console.log(`[error] ${error}`);
      };

      state.socket = socket;
    },
    setCurrentLeashInput: (state, { payload }: PayloadAction<any>) => {
      state.currentLeashInput = payload.currentLeashInput;
    },
    setLateralOffset(state, { payload }: PayloadAction<any>) {
      if (payload.lateralOffset === "left") {
        state.lateralOffset = state.lateralOffset - 1;
        if (state.lateralOffset < MIN_LATERAL_OFFSET) {
          state.lateralOffset = MIN_LATERAL_OFFSET;
        }
      } else if (payload.lateralOffset === "right") {
        state.lateralOffset = state.lateralOffset + 1;
        if (state.lateralOffset > MAX_LATERAL_OFFSET) {
          state.lateralOffset = MAX_LATERAL_OFFSET;
        }
      } else if (payload.lateralOffset === "reset") {
        state.lateralOffset = DEFAULT_LATERAL_OFFSET;
      }
      state.socket?.send(
        JSON.stringify({
          action: "sendTeleassistCommand",
          vehicle_id: state.vehicleId,
          id: payload.buttonId,
          value: state.lateralOffset,
        })
      );
    },
    setLongitudinalOffset: (state, { payload }: PayloadAction<any>) => {
      if (payload.longitudinalOffset === "down"){
        state.longitudinalOffset  = state.longitudinalOffset - 1;
        if(state.longitudinalOffset < MIN_LONGITUDINAL_OFFSET){
          state.longitudinalOffset = MIN_LONGITUDINAL_OFFSET;
        }
      } else if (payload.longitudinalOffset === "up"){
        state.longitudinalOffset = state.longitudinalOffset + 1;
        if (state.longitudinalOffset > MAX_LONGITUDINAL_OFFSET){
          state.longitudinalOffset = MAX_LONGITUDINAL_OFFSET;
        }
      } else if (payload.longitudinalOffset === "reset"){
        state.longitudinalOffset = DEFAULT_LONGITUDINAL_OFFSET;
      }
      state.socket?.send(
        JSON.stringify({
          action: "sendTeleassistCommand",
          vehicle_id: state.vehicleId,
          id: payload.buttonId,
          value: state.longitudinalOffset,
        })
      );
    },
    setDistance(state, { payload }: PayloadAction<any>) {
      state.distance = payload.distance;
    },
    sendDistance(state, { payload }: PayloadAction<any>) {
      state.distance = payload.distance;
      if (Date.now() - state.lastSentLeashTime > 100 || payload.distance === 0){
        state.lastSentLeashTime = Date.now();
        state.socket?.send(
          JSON.stringify({
            action: "sendTeleassistCommand",
            vehicle_id: state.vehicleId,
            id: payload.buttonId,
            value: state.distance,
          })
        );
      }
    },
    sendReady(state) {
      state.socket?.send(
        JSON.stringify({
          action: "sendTeleassistCommand",
          vehicle_id: state.vehicleId,
          id: 1,
          value: 0,
        })
      );
      state.pressedXKey = XKeyIndex.Ready;
    },
    sendAuthorize(state) {
      state.socket?.send(
        JSON.stringify({
          action: "sendTeleassistCommand",
          vehicle_id: state.vehicleId,
          id: 4,
          value: 0,
        })
      );
      state.pressedXKey = XKeyIndex.Authorize;
    },
    sendEventRequest(state) {
      if (state.eventFlagState === EventFlagStateIndex.Off){
        var flag_time = new Date();
        state.socket?.send(
          JSON.stringify({
            action: "sendEventRequest",
            vehicle_id: state.vehicleId,
            tag: flag_time.toISOString(),
            flag_time_frontend: flag_time.toISOString(),
          })
        ); 
        state.eventFlagState = EventFlagStateIndex.Requested;
      }
    },
    sendRecordingRequest(state) {
      if (state.recordingState === RecordingStateIndex.Off) {
        var recording_time = new Date();
        state.socket?.send(
          JSON.stringify({
            action: "sendRecordingRequest",
            vehicle_id: state.vehicleId,
            recording_start_timestamp: recording_time.toISOString(),
            name: recording_time.toISOString(),
            recording_id: recording_time.toISOString(),
          })
        );
        state.recordingState = RecordingStateIndex.RequestedOn;
      } else if (state.recordingState === RecordingStateIndex.Recording) {
        state.socket?.send(
          JSON.stringify({
            action: "sendRecordingRequest",
            vehicle_id: state.vehicleId,
            recording_start_timestamp: "recording_end",
            name: "recording_end",
            recording_id: "recording_end",
          })
        );
        state.recordingState = RecordingStateIndex.RequestedOff;
      }
    },
    receiveRecordingStatus(state, { payload }) {
      if (payload.recordingStatus) {
        state.recordingState = RecordingStateIndex.Recording;
      } else {
        state.recordingState = RecordingStateIndex.Off;
      }
    },
    receiveEventAcknowledgement(state, { payload }){
      if (state.eventFlagState === EventFlagStateIndex.Requested){
        if (payload.eventStatus){
          state.eventFlagState = EventFlagStateIndex.Off;
        }
      }
    },
    resetPressedXKey: (state) => {
      state.pressedXKey = null;
    },
    updateTelemetry(state, { payload }: PayloadAction<any>) {
      state.telemetry = {
        ...state.telemetry,
        ...payload,
      };
    },
    updateVideoRootDimensions(state, { payload }: PayloadAction<any>) {
      state.videoRootHeight = payload.height;
      state.videoRootWidth = payload.width;
    },
    updateRelay(state, { payload }: PayloadAction<any>) {
      state.relay = {
        ...state.relay,
        ...payload,
      };
    },
    updateMaxPlannedSpeedMph(state, { payload }: PayloadAction<any>) {
      state.maxPlannedSpeedMph = payload.maxPlannedSpeedMph;
    },
    updateGPS(state, { payload }: PayloadAction<number[]>) {
      state.gps = payload;
    },
    updateYaw(state, { payload }: PayloadAction<number>) {
      state.yaw = payload;
    },
    setVehicles(state, { payload }: PayloadAction<any>) {
      if (payload) {
        try {
          state.vehicles =
            typeof payload === "string" ? JSON.parse(payload) : payload;
          if (!state.vehicleId) {
            state.vehicleId = Object.keys(state.vehicles)[0];
            state.appMode = 0;
            state.lateralOffset = DEFAULT_LATERAL_OFFSET;
            state.longitudinalOffset = DEFAULT_LONGITUDINAL_OFFSET;
            state.distance = DEFAULT_DISTANCE;
            state.message = "";
            state.telemetry = {
              ...initialState.telemetry,
              Speed: "0",
              Accel: 0,
              Brake: 0,
              Steering: 0,
            };
            state.relay = {
              MaxPlannedSpeed: "0",
              DelimitedSpeed: "0",
            };
            state.currentLeashInput = "0";
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    toggleAppUI(state) {
      state.appShowUI = !state.appShowUI;
      state.pressedXKey = XKeyIndex.Eye;
    },
    setShuttleValue(state, { payload }: PayloadAction<number>) {
      state.shuttle = payload;
    },
    setPressedXKey(state, { payload }: PayloadAction<XKey>) {
      state.pressedXKey = payload;
    },
    enableRearCamera(state, { payload }: PayloadAction<boolean>) {
      state.rearCameraEnabled = payload;
    },
  },
});

export const dataActions = dataSlice.actions;
export const dataReducer = dataSlice.reducer;
