import { motion, Variant } from "framer-motion";
import { cloneElement, FC, PropsWithChildren, useMemo } from "react";
import styled from "styled-components";

const Container = styled(motion.div)<{ color?: string }>`
  min-width: 100vw;
  height: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.color ?? ""};
  position: absolute;
  overflow-x: hidden;
  overflow: hidden;
`;
 
const Slider = styled(motion.div)`
  min-width: 100vw;
  height: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: row;
  position: absolute;
`;

export const Slide = styled.div<{ color?: string }>`
  /* width: 700px; */
  /* width: 75vw; */
  width: 100vw;
  /* height: 100vh; */
  background-color: ${(props) => props.color ?? "whtie"};
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
`;

export interface FullscreenSliderProps {
  numberOfSlides: number;
  slide?: number;
}

export const FullscreenSlider: FC<PropsWithChildren<FullscreenSliderProps>> = (
  props
) => {
  const { children, numberOfSlides, slide } = props;

  const variants = useMemo<{ [key: `slide${number}`]: Variant }>(() => {
    return new Array(numberOfSlides)
      .fill(null)
      .map((_, index) => index)
      .reduce(
        (obj, slideIndex) => ({
          ...obj,
          [`slide${slideIndex}`]: {
            left: `-${slideIndex * 100}vw`,
            transition: {
              delay: 0.05,
              duration: 0.35,
              ease: "easeOut",

              //   ease: "linear",
            },
          } as Variant,
        }),
        {} as { [key: `slide${number}`]: Variant }
      );

    // variants.slide2.
  }, [numberOfSlides]);
  console.log({ numberOfSlides, slide, variants });

  return (
    <Container initial={{}}>
      <Slider
        initial={`slide${slide ?? 0}`}
        // initial={{ left: "-100vw" }}
        animate={
          `slide${slide ?? 0}`
          //   { none: "default", left: "lookLeft", right: "lookRight" }[keyPressed]
        }
        variants={
          variants
          //     {
          //   default: {
          //     left: "-100vw",
          //     transition: {
          //       delay: 0.05,
          //       duration: 0.35,
          //       ease: "easeOut",

          //       //   ease: "linear",
          //     },
          //   },
          //   lookLeft: {
          //     left: 0,
          //     transition: {
          //       delay: 0.05,
          //       duration: 0.35,
          //       ease: "easeIn",
          //     },
          //   },
          //   lookRight: {
          //     left: "-200vw",
          //     // transition
          //     transition: {
          //       delay: 0.05,
          //       duration: 0.35,
          //       ease: "easeIn",

          //       //   ease: "linear",

          //       // ease: {
          //       // mass: 2,
          //       // stiffness: 5,
          //       // damping: 10
          //       // },
          //       //   ease: [0.43, 0.62, 0.83, 0.67],
          //       // ease: "easeIn",
          //     },
          //   },

          // }
        }
      >
        {children}
        {/* <Slide>
          <img src={SlideImageOne} />
        </Slide>
        <Slide>
          <img src={SlideImageTwo} />
        </Slide>
        <Slide>
          <img src={SlideImageThree} />
        </Slide> */}

        {/* <Slide color="#24da79">slide 1</Slide>
      <Slide color="#242ada">slide 2</Slide>
      <Slide color="#da24c2">slide 3</Slide> */}
      </Slider>
    </Container>
  );
};

// export const Slide
