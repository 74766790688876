export const ID_UP = 5;
export const ID_DOWN = 6;
export const ID_LEFT = 7;
export const ID_H_SLIDER = 9;
export const ID_V_SLIDER = 10;

export const modeMap: any = {
  0: "Inactive",
  1: "Not ready",
  2: "Ready",
  3: "Driverless",
  4: "Manual",
  5: "Teleop",
  6: "Rescue",
  7: "SHP1",
  8: "SHP 2",
  9: "SHP 4",
  10: "Pit",
};

export const DEFAULT_DISTANCE = 50;
export const DEFAULT_LONGITUDINAL_OFFSET = 50;
export const MAX_LONGITUDINAL_OFFSET = 70;
export const MIN_LONGITUDINAL_OFFSET = 50;
export const DEFAULT_LATERAL_OFFSET = 50;
export const MAX_LATERAL_OFFSET = 60;
export const MIN_LATERAL_OFFSET = 40;
