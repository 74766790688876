import { FC } from "react";
import styled from "styled-components";
// import {route} from 'react-router'

import { ReactComponent as PolygonSvg } from "./assets/poly.svg";

const Container = styled.div`
  width: 100%;
  max-width: 1420px;
  min-height: 320px;
  background-color: #fafafa;
`;

const Table = styled.table`
  background-color: gray;
  width: 100%;
  border: none;
  overflow: hidden;
`;

const TableHead = styled.thead`
  /* display: block; */
`;

const TableBody = styled.tbody`
  /* height: 500px; */
  /* display: block; */
  /* overflow-y: auto; */
`;

const HeadRow = styled.tr`
  height: 70px;
  min-height: 70px;
  background-color: white;
`;

const BodyCell = styled.td`
  font-style: normal;
  font-weight: normal !important;
  font-size: 22px;
  line-height: 29px;
  color: black;

  transition: color 150ms ease-in-out;
`;

const Row = styled.tr`
  background-color: white;
  min-height: 70px;
  height: 70px;

  cursor: pointer;

  transition: background-color 150ms ease-in-out;

  &:nth-child(even) {
    background-color: #e9e9e9;
  }

  &:hover {
    background-color: #7c7c7c;
    color: white;
    ${BodyCell} {
      color: white;
    }
  }

  &:active,
  &:focus {
    background-color: #2763fd;
    ${BodyCell} {
      color: white;
      &::selection {
        background-color: white;
        color: black;
      }
    }
  }
`;

const HeadCell = styled.th`
  font-style: normal;
  font-weight: 400 !important;
  font-size: 22px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;

  transition: color 150ms ease-in-out;

  &:hover {
    color: black;
    fill: black;
  }
`;

const VehicleLink = styled.a`
  color: inherit;
`;

const Caption = styled.caption`
  font-weight: 500;
  font-size: 24px;
  color: black;
`;

const SortArrow = styled(PolygonSvg)`
  margin-right: 4px;
  margin-bottom: 1px;
`;

export interface VehicleRowProps {
  vehicle: Vehicle;
}

const VehicleRow: FC<VehicleRowProps> = (props) => {
  const { vehicle } = props;
  const { id } = vehicle;

  return (
    <Row>
      <BodyCell>
        <VehicleLink href={`/vehicle/${id}`}>{id}</VehicleLink>
      </BodyCell>
      <BodyCell>-</BodyCell>
      <BodyCell>-</BodyCell>
      <BodyCell>-</BodyCell>
      <BodyCell>-</BodyCell>
      <BodyCell>####.###.##</BodyCell>
      <BodyCell>####.###.##</BodyCell>
      {/* Test Data */}
      {/* <BodyCell>Cocola</BodyCell>
      <BodyCell>Driverless</BodyCell>
      <BodyCell>Delivery</BodyCell>
      <BodyCell>87%</BodyCell>
      <BodyCell>####.###.##</BodyCell>
      <BodyCell>####.###.##</BodyCell> */}
    </Row>
  );
};

export interface Vehicle {
  id: string;
  channel: string;
}

export interface VehiclesTableProps {
  caption?: string;
  vehicles: Vehicle[];
}

export const VehiclesTable: FC<VehiclesTableProps> = (props) => {
  const { caption, vehicles } = props;

  // const testRows = <>
  //    <VehicleRow vehicleName="FUV 114" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  //         <VehicleRow vehicleName="FUV 115" />
  // </>

  return (
    <Container>
      <Table cellSpacing="0" cellPadding="0">
        {!!caption && <Caption>{caption}</Caption>}
        <TableHead>
          <HeadRow>
            <HeadCell>
              <SortArrow />
              <span>Vehicle ID</span>
            </HeadCell>
            <HeadCell>Mission</HeadCell>
            <HeadCell>State</HeadCell>
            <HeadCell>Config</HeadCell>
            <HeadCell>Charge</HeadCell>
            <HeadCell>Software Version</HeadCell>
            <HeadCell>Hardware Version</HeadCell>
          </HeadRow>
        </TableHead>
        <TableBody>
          {vehicles?.map((vehicle) => {
            return <VehicleRow key={vehicle.id} vehicle={vehicle} />;
          })}
        </TableBody>
      </Table>
    </Container>
  );
};

export default VehiclesTable;
