/* eslint-disable import/no-anonymous-default-export */
const userPoolId = "us-west-2_mHMQAqOJN";
const identityPoolId = "us-west-2:7ad5cf63-ff64-48b2-94e7-bf240dec2ee3";
const appClientId = "i30dm5mqb5vdv63a8d1v330ri";
const region = "us-west-2";

export default {
  Auth: {
    mandatorySignIn: true,
    region: region,
    userPoolId: userPoolId,
    userPoolWebClientId: appClientId,
    identityPoolId,
  },
};
