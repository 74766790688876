import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserSliceState {
  username: string | null;
  userSub: string;
  roles: string[] | null;
}

const initialState: UserSliceState = {
  username: null,
  userSub: "",
  roles: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsername: (state, { payload }: PayloadAction<string | null>) => {
      state.username = payload;
    },
    setUserSub: (state, { payload }: PayloadAction<string>) => {
      state.userSub = payload;
    },
    setUserRoles: (state, { payload }: PayloadAction<string[] | null>) => {
      state.roles = payload;
    },
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
